.App {
  text-align: right;
}

.Content{
  padding: 20px;
}

body{
  background-image: url("bg.jpg");
  background-size: cover;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  font-size: calc(10px + 2vmin);
  color: white;
}
